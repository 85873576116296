@tailwind base;
@tailwind components;
@tailwind utilities;

.heading{
    background: radial-gradient(circle, #ffd400 3%, #ff5e00 97%);
}

.resulttableIstcol{
    background:linear-gradient(360deg, #ff9133 0, rgb(255 83 0) 100%);
}  
